import React from 'react'
import { NavbarWithMegaMenu } from './NavBar'


function Header() {
  return (
    <div className='bg-newbg  py-4 mx-3'>

   

    <div className=''>
    <NavbarWithMegaMenu/>
    </div>

   
    
    </div>
  )
}

export default Header