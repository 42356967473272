
import './App.css';
import CompanyRoute from './Routes/CompanyRoute';

function App() {
  return (
    <div className="App">
      <CompanyRoute/>
    </div>
  );
}

export default App;
